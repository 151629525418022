<template>
<div>
  <b-form>
    <!-- <b-row class="mb-1">
      <b-col>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mt-2 ml-1"
          >
            <feather-icon icon="SettingsIcon" class="mr-50"/>
              <span class="align-middle">Einstellungen</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mt-2 ml-1"
          >
            <feather-icon icon="CodesandboxIcon" class="mr-50"/>
              <span class="align-middle">Prompt Generator</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mt-2 ml-1"
          >
            <feather-icon icon="BookOpenIcon" class="mr-50"/>
              <span class="align-middle">Prompt Bibliothek</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mt-2 ml-1"
          >
            <feather-icon icon="FolderIcon" class="mr-50"/>
              <span class="align-middle">Verlauf</span>
        </b-button>
      </b-col>
    </b-row> -->

    <div>
      <div v-for="ans in answers" :key="ans.id">
        <div v-if="ans.id % 2 !== 0"><b-card class="mr-3" bg-variant="secondary" text-variant="white">{{ ans.text }}</b-card></div>
        <!-- <div :style="{'background-color': 'Silver', 'color': 'black'}" v-else><p>{{ ans.text }}</p></div> -->
        <!-- <div v-else-if="ans.isSep === true"> -->
          <b-card class="ml-3 d-flex align-items-center" img-src="../../assets/images/ico/separis.png" img-height="40" img-width="40" img-left>
            <b-card-text style="white-space: pre-line;" bg-variant="light" text-variant="black" v-html="ans.text"></b-card-text>
          </b-card></div>
          <!-- <div v-else>
          <b-card class="ml-3 d-flex align-items-center" img-src="../../assets/images/ico/ChatGPT.png" img-height="40" img-width="40" img-left>
            <b-card-text style="white-space: pre-line;" bg-variant="light" text-variant="black" v-html="ans.text"></b-card-text>
          </b-card></div>
        </div> -->
    </div>

    <b-overlay :show="isCalc" spinner-variant="dark" spinner-type="grow" rounded="sm" opacity="0.6">
      <b-row align-v="center">
        <b-col cols="12" md="11">
            <!-- <b-form-textarea :style="{'background-color': 'LightGray', 'color': 'black'}" v-model="question" rows="2" max-rows="4" @keydown.enter="getChatGPTAnswer"/> -->
            <!-- <b-form-textarea v-model="question" rows="2" max-rows="4" @keydown.enter="getChatGPTAnswer"/> -->
            <b-form-textarea v-model="question" rows="2" max-rows="4"/>
        </b-col>

        <!-- <b-col cols="12" md="1">
          <b-form-checkbox v-model="isSep" class="custom-control-primary mt-1" name="check-button" switch >{{ isSep ? 'separis' : 'ChatGPT' }}</b-form-checkbox>
        </b-col> -->
        <b-col cols="12" md="1">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="lg" variant="outline-primary" class="btn-icon"
            @click="getChatGPTAnswer"
          >
            <feather-icon icon="SendIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>
  </b-form>
  </div>
</template>

<script>

import {
  BButton, BCard, BCardText, BRow, BCol, BForm, BFormTextarea, BOverlay, // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Configuration, OpenAIApi } from 'openai'
import axios from '@axios'

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BForm,
    BFormTextarea,
    BOverlay,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: null,
      openai: null,
      question: '',
      isCalc: false,
      answers: [],
      isSep: true,
      context: '',
    }
  },
  async created() {
    this.config = new Configuration({
      apiKey: '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',
    })
    this.openai = new OpenAIApi(this.config)
  },
  methods: {
    async getChatGPTAnswer() {
      if (this.question === '') return

      this.isCalc = true

      this.answers.push({
        id: this.answers.length + 1,
        text: this.question,
      })

      try {
        // const r = await this.openai.createCompletion({
        //   model: 'text-davinci-003',
        //   prompt: this.question + this.context,
        //   max_tokens: 2000,
        //   temperature: 0.5,
        // })
        // if (r.data.choices[0].text !== '' && r.data.choices[0].text !== null) {
        //   this.answers.push({
        //     id: this.answers.length + 1,
        //     text: r.data.choices[0].text,
        //   })
        // } else {
        //   console.log('response empty..')
        // }

        const r = await this.postPrompt(this.question)
        if (r !== null && r.data !== null && r.data.output !== '') {
          this.answers.push({
            id: this.answers.length + 1,
            text: r.data.output,
            isSep: this.isSep,
          })
          // console.log(r.data.output)
          // console.log(this.answers[this.answers.length - 1].text)
        } else {
          // console.log('response empty..')
        }

        // this.context += this.question + '  ' + r.data.choices[0].text + '  '
        this.context += this.question + '  ' + r.data.output + '  '
        this.isCalc = false
        this.question = ''
      } catch (error) {
        // console.log(error)
      }
    },
    async postPrompt(prompt) {
      try {
        const r = await axios.post('https://eru.pythonanywhere.com/askGPT', {
          prompt,
          // isseparis: this.isSep ? 1 : 0,
          isseparis: 1,
          pak: '115dea1d-893b-4725-8f30-d06799f343ff',
          oak: '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',
        }, { headers: { 'Content-Type': 'application/json' } })

        // const r = await axios.post('http://localhost:5000//askGPT', {
        //   prompt,
        //   isseparis: this.isSep ? 1 : 0,
        //   pak: '115dea1d-893b-4725-8f30-d06799f343ff',
        //   oak: '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',
        // }, { headers: { 'Content-Type': 'application/json' } })

        // const r = await axios.get('http://localhost:5000/ask', { // als url encoded!
        //   headers: { Accept: 'application/json' },
        //   params: {
        //     prompt,
        //   },
        // })
        return r
      } catch (error) {
        // console.log(error)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
</style>
